import React from 'react';
import { fileType } from './document.js';
import { fetchGet, getWispUrl, wisp_urls } from './fetch.js';
import { getDateStr, limitString } from '../util/base.js';
import { wispHistory } from '../components/WispHistory.js';

export function hasSiteAccess(site) {
	if (typeof site === 'undefined') {
		return false;
	}
	if (site.user_access && site.user_access.role === 'admin') {
		return true;
	}

	if (site.access === 'public') {
		return true;
	} else if (site.access === 'invite') {	// not used? TBD
		return false;
	} else {	// subscription
		var today = new Date();  
		var time_now = today.getTime();
		var time = site.user_access.end_date;
		var ttime = new Date(time);
		if(ttime < time_now){
			return true;
		}
		return false;
	}
}

export function getSiteExpireText(site) {
	if (typeof site === 'undefined' || (site.user_access && site.user_access.role === 'admin')) {
		return null;
	}
	if (site.access === 'subscription') {
		var today = new Date();  
		var time_now = today.getTime();
		var time = site.user_access.end_date;
		var ttime = new Date(time);
		if(ttime < time_now){
			return (
				<span style={{color:'#880000'}}>
					Expired: {getDateStr(time,false,false)}
				</span>
			);
		}
		return (
			<span style={{}}>
				Expires: {getDateStr(time,false,false)}
			</span>
		);    
	}
	return null;
}


// this method doesn't look good!
export function siteAccessStatus(siteUrl) {
    if (typeof siteUrl === 'undefined' || siteUrl === "") {
        return {status: "none"};
    }
    var wisp_user = window.wisp_user;
    if(wisp_user && typeof ts_subscriptions === 'undefined') {
/*
        var hasExpired = false;
        var hasCurrent = false;
        var today = new Date();  
        var time_now = today.getTime();
        $(ts_subscriptions).find('subscription').each(function(i){ 
            var team = $(this).attr('team');
            if(team == po){
                var time = $(this).attr('end');
                if(parseInt(time) < time_now){
                    hasExpired = true;
                } else {
                    hasCurrent = true;
                }
            }
        });
        return (hasExpired && !hasCurrent); */
    }
    return {status: "none"};
}

export function getSiteUrlFromUrl(href, hasUserId) {
	var siteurl = null;
	var thref = href.toLowerCase();

	// drop http/https://
	var i = thref.indexOf('https');
	if (i === 0) {
		thref = thref.substring(8);
	}
	i = thref.indexOf('http');
	if (i === 0) {
		thref = thref.substring(7);
	}

	var subDomains = process.env.REACT_APP_SUBDOMAINS;
	if (subDomains === "true") {
		var appName = process.env.REACT_APP_NAME;
		appName = appName.toLowerCase() + '.com';

		i = thref.indexOf('www.');
		if (i >= 0) {
			thref = thref.substring(0, i);
		} else {
			i = thref.indexOf('app.localhost');
			if (i >= 0) {
				thref = thref.substring(0, i);
			}
			i = thref.indexOf(appName);
			if (i >= 0) {
				thref = thref.substring(0, i);
			}
		}
		i = thref.indexOf('.');
		if (i > 0) {
			siteurl = thref.substring(0, i);
		}
	} else {
		// for some reason wispbook.com?referral= gets changed to wispbook.com/?referral=
		// so / doesn't mean there's a site url
		i = thref.indexOf('/');
		var qi = thref.indexOf('?');
		if (i > 0 && qi != (i+1)) {
			if (hasUserId) {
				i = thref.indexOf('/', i+1);	// skip the user id
			}
			if (i > 0) {
				siteurl = thref.substring(i+1);
			}
			i = siteurl.indexOf('/');
			if (i > 0) {
				siteurl = siteurl.substring(0,i);
			}
		}
	}
	return siteurl;
}

export function getDocIdFromUrl(href) {
	var i = href.indexOf('id=');
	if (i > 0) {
		var i2 = href.indexOf('&', i+3);
		if (i2 > i) {
		  return href.substring(i+3,i2);
		} else {
		  return href.substring(i+3);
		}
	}
	return null;
}

export function getReferralFromUrl(href) {
	var referral = '';
	var i = href.indexOf('?referral=');
	if (i > 0) {
		referral = href.substring(i+10);
	}
	return referral;
}

export function getSiteRedirectUrl(site) {
	return process.env.REACT_APP_PROTOCOL + "://" + site.url + '.' + process.env.REACT_APP_URL_POSTFIX;
}

// ignore docs that are linked to other docs (used by captions.docx and completed workouts.xlsx)
export function getFirstPageDoc(pageId, siteDocMaps) {
	var themap = null;
	siteDocMaps.forEach((map) => {
		if (map.page_id === parseInt(pageId) && themap === null && map.link_doc_id === null) {
			themap = map;
		}
	});
	return themap;
}

export function getNextPageDoc(pageId, siteDocMaps) {
	var themap = null;
	var found = false;
	siteDocMaps.forEach((map) => {
		if (map.page_id === parseInt(pageId) && themap === null) {
			found = true;
		} else if(found) {
			themap = map;
		}
	});
	return themap;
}

export function getNumPageDocs(pageId, wispData) {
	var count = 0;
	var maps = wispData.siteDocMaps;
	if (maps) {
		maps.forEach((map) => {
			if (map.page_id === parseInt(pageId)) {
				count = count + 1;
			}
		});
	}
	return count;

}

export function goToSite(url, userId, edit) {
	var subDomains = process.env.REACT_APP_SUBDOMAINS;
	if (subDomains === "true") {
		var newurl = process.env.REACT_APP_PROTOCOL + '://' + url + '.' + process.env.REACT_APP_URL_POSTFIX;
		window.location.href = newurl;
	} else {
		var turl = '';
		if (userId && userId.length > 0) {
			turl += '/' + userId;
		}
		turl += '/' + url;
		if (edit) {
			turl += "/edit";
		}
		wispHistory.push(turl);
	}
}

export function checkUrl(url) {
	if (url === null || url.length === 0) {
		return 'Please enter a URL';
	}
	url = url.toLowerCase();
	if (url.indexOf('_') >= 0) {
		return '_ not allowed in URLs';
	} else if (url === 'admin' || url === 'api') {
		return 'Reserved keywords not allowed';
	}

	return 'valid';
}

export function isSiteAuthor(site) {
	if (site && site.user_access && (site.user_access.role == 'admin' || site.user_access.role === 'author')) {
		return true;
	}
	return false;
}

export function isSiteAdmin(userSites) {
    var admin = null;
    if (userSites) {
        admin = false;
        userSites.forEach((site) => {
			if (site.user_access.role === 'admin') {
                admin = true;
            }
        })
    }
	return admin;
}

export function getSiteFromId(id, userSites){
    var match_site = null;
    if (userSites) {
        userSites.forEach((site) => {
            if (site.id === id) {
                match_site = site;
            }
        })
    }
    return match_site;
}

export function getSiteFromUrl(site_url, userSites){
    var match_site = null;
    if (userSites) {
        userSites.forEach((site) => {
            if (site.url === site_url) {
                match_site = site;
            }
        })
    }
    return match_site;
}

export function getSiteFromName(site_name, userSites){
    var match_site = null;
    if (userSites) {
        userSites.forEach((site) => {
            if (site.name === site_name) {
                match_site = site
            }
        })
    }
    return match_site;
}

export function isForSale(site) {
    if (site.state === 'published' && site.price > 0) {
        return true;
    }
    return false;
}

export function eventInProgress(sites) {
	var siteInProgress = null;
	var date = new Date();
	date.setHours(0, 0, 0, 0);
	sites.forEach((site) => {
		if (site.date_start && site.date_end) {
			var startDate = new Date(site.date_start);
			var endDate = new Date(site.date_end);
			startDate.setHours(0, 0, 0, 0)
			endDate.setHours(0, 0, 0, 0)
			if (date >= startDate && date <= endDate) {
				siteInProgress = site;
			}
		}

	})
	return siteInProgress;
}

export function upcomingEvent(sites) {
	var upComingSite = null;
	var date = new Date();
	date.setHours(0, 0, 0, 0);
	sites.forEach((site) => {
		if (site.date_start && site.date_end) {
			var startDate = new Date(site.date_start);
			startDate.setHours(0, 0, 0, 0)
			if (date <= startDate) {
				upComingSite = site;
			}
		}

	})
	return upComingSite;
}

// not called at the moment (6/2)
export function loadSiteDocs(docMaps, wispData, getDocResults) {
	docMaps.forEach((map) => {
		if (!wispData.docContent[map.doc_id] && fileType(map.filename) != 'pdf') {
			var url = getWispUrl(wisp_urls.document_get, map.doc_id);
			if (wispData.mobileView) {
				url += "?view=mobile";
			} else {
				url += "?view=desktop";
			}
			url += "&format=html";
			// setting force update to true because docContent is only null under two scenarios: 1) haven't got it yet, 2) did update doc in booksettings and cleared out the html
			fetchGet(url, getDocResults, wispData, true, map, { style: 'blog' }, 'html');
		} else {
		}

	});
}

export function eventToUrl(event) {
	// var topics = ["Cotswold Way", "John Muir Trail","LEJOG","NH 4000 Footers", "Other"];
	var url = '';
	url = event.toLowerCase();
	url = url.replace(/\s/g, '');
	if (url.length > 10) {
		url = url.substring(0,10);
	}
	return url;
}

export function getSiteMembers(site_id, roles, doneFN, arg) {
	var url = getWispUrl(wisp_urls.site_members_get, site_id)
	var headers = {
		'Content-Type': 'application/json',
		roles: roles,
	}
	fetch(url, {method: 'GET', mode: 'cors', headers})
	.then(function(response) {
		console.log('response.status = ' + response.status); // Will show you the status
		return response.json();
	})
	.then(data => {
		doneFN(data, arg);
	})
	.catch((error) => {
		console.error('Error:', error);
		doneFN(null, arg);
	});

}

export function getSiteAdmin(site, wispData, gotFN) {
	if (site.user_access && site.user_access.role === 'admin') {
		gotFN(wispData.publicState.wispUser);
	}
	getSiteMembers(site.id, 'admin', gotSiteMembers, gotFN);
}

function gotSiteMembers(data, doneFN) {
	if (data && data.length > 0) {
		doneFN(data[0]);
	}
	doneFN(null);
}

export function completeInvite(wispData, sitemap, doneFN, userId, token) {
	var headers = {
		'Authorization': 'Bearer ' + token,
		'Content-Type': 'application/json',
		'user-id': userId,
	}
	sitemap.state = 'active';
	var url = getWispUrl(wisp_urls.sitemap_edit, sitemap.id);

	fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(sitemap) })	//  mode: 'cors', headers: headers,
		.then(function (response) {
			console.log('response.status = ' + response.status); // Will show you the status
			return response.json();
		})
		.then(data => {
			console.log('success = ' + data);
			// doneFN needs to clear these out when it's done with them - WispBook site invite needs them
			// wispData.setInviteSitemap(null);
			// wispData.setInviteSite(null);
			doneFN(userId, token);
		})
		.catch((error) => {
			console.error('Error:', error);
			doneFN(null);
		});
}

export function getSiteAuthor(wispData, siteId, authorId) {
	var member;
	var authors = wispData.siteAuthors[siteId];
	if (authors) {
		authors.forEach((author) => {
			if (author.user_id === authorId) {
				member = author;
			}
		});
	}
	return member;
}

export function getUniqueSiteName(name, sites) {
	var done = false;
	var newname;
	var count = 1;
	while (!done) {
		newname = name;
		if (count >= 2) {
			newname = name + ' ' + count;
		}
		var found = false;
		sites.forEach((site) => {
			if (site.name === newname) {
				found = true;
			}
		});
		if (!found) {
			done = true;
		}
		count++;
	}
	return newname;
}

export function getActiveExercise(exerciseId, workoutMap, wispData) {
	if (workoutMap) {
		// get the workout data
		var content = wispData.docContent;
		var data = content[workoutMap.doc_id];
		if (data && data.detail != "document doesn't exist") {
			var cells = data['cells'];
			for (var i = 0;i < cells.length;i++) {
				var row = cells[i];
				if (row['cols'][0] && row['cols'][0]['v'] != '') {
					var tid = row['cols'][0]['v'];
					if (tid === exerciseId) {
						var active = row['cols'][1]['v'];
						if (active === 'true') {
							return row;
						}
					}
				}
			}
		}
	}
	return null;
}

export function createSite(wispData, url, fn) {
	var user = wispData.publicState.wispUser;
	var siteData = {
		access: 'public',
		agreed: false,
		app_id: process.env.REACT_APP_ID,
		description: user.first_name + '\'s book',
		name: user.first_name + '\'s book',
		price: 0,
		url: url,
	};
	var token = wispData.token;
	var headers = {
		'Authorization': 'Bearer ' + token,
		'Content-Type': 'application/json',
		'user-id': wispData.publicState.wispUser.id,
		'app-id': process.env.REACT_APP_ID,
	}
	var url = wisp_urls.site_create;
	fetch(url, { method: 'POST', mode: 'cors', headers: headers, body: JSON.stringify(siteData) })
		.then(function (response) {
			console.log('response.status = ' + response.status); // Will show you the status
			return response.json();
		})
		.then(data => {
			if (fn) {
				fn(data['url'], '')
			}
			// goToSite(data['url'], '');
			// props.updateSitesFN(data['url']);
		})
		.catch((error) => {
			console.error('Error:', error);
		});

}

export function createSiteMap(wispData, role, siteId, state, email, fn) {

	var token = wispData.token;
	// var user = wispData.publicState.wispUser;
	var headers = {
		'Authorization': 'Bearer ' + token,
		'Content-Type': 'application/json',
		'user-id': wispData.publicState.wispUser.id,
	}
	// turns out you really have to do state = 'invited' because of the email address
	var sitemap = {
		role: role,
		site_id: siteId,
		state: state,
		user_id: email,
	};
	var url = wisp_urls.sitemap_create;
	fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(sitemap) })	//  mode: 'cors', headers: headers,
		.then(function (response) {
			console.log('response.status = ' + response.status); // Will show you the status
			return response.json();
		})
		.then(data => {
			console.log('success = ' + data);
			if (fn) {
				fn(data);
			}
		})
		.catch((error) => {
			if (fn) {
				fn();
			}
		});	
}

export function deleteSiteMap(wispData, sitemapId, doneFN) {
	var url = getWispUrl(wisp_urls.sitemap_delete, sitemapId);
	var token = wispData.token;
	var headers = {
		'Content-Type': 'application/json',
		'app-id': process.env.REACT_APP_ID,
		'Authorization': 'Bearer ' + token,
		'user-id': wispData.publicState.wispUser.id,
	}
	fetch(url, { method: 'POST', mode: 'cors', headers })
		.then(function (response) {
			console.log('response.status = ' + response.status); // Will show you the status
			return response.json();
		})
		.then(data => {
			console.log('success = ' + data);
			doneFN(sitemapId, true);
		})
		.catch((error) => {
			console.error('Error:', error);
			doneFN(sitemapId, false);
		});

}

export function getDescription(site, wispData) {
	var tdesc = '';
	if (site) {
		var tdesc = site.description;
		if (site.app_data && wispData.userSitesNav) {
			var ad = site.app_data;
			tdesc = (
				<div className='navDescriptDiv'>
					<div className='navDescript'>
						<div className='descLabel'>This Trip</div>
						{tdesc}
					</div>
				</div>
			);

		}
	}
	return tdesc;
} 

export function setTripPrevId(id, linkId, userSites, wispData) {
	var site = getSiteFromId(id, userSites);
	if (site && site.app_data) {
		site.app_data.trip_prev_id = linkId;
		setTripLinkId(site, wispData);
	}
}

export function setTripNextId(id, linkId, userSites, wispData) {
	var site = getSiteFromId(id, userSites);
	if (site && site.app_data) {
		site.app_data.trip_next_id = linkId;
		setTripLinkId(site, wispData);
	}
}

export function setTripLinkId(site, wispData) {
	// NICE! dates from TEngine get serialized like "2021-04-30T06:00:00+00:00" but javascript dates get serialized like "2021-04-30T06:00:00.000Z" and this is what TEngine expects when editing a site
	// so getting an error when passing site data back to TEngine
	// gonna fix TEngine to support the freaking format it provides
	/* var tdate = new Date(site.date_start);
	site.date_start = tdate;
	tdate = new Date(site.date_end);
	site.date_start = tdate; */

	var token = wispData.token;
	var headers = {
		'Content-Type': 'application/json',
		'Authorization': 'Bearer ' + token,
		'user-id': wispData.publicState.wispUser.id,
	}
	var url = getWispUrl(wisp_urls.site_edit, site.id);
	var status;
	console.log('url = ' + url);
	fetch(url, {method: 'POST', mode: 'cors', headers, body: JSON.stringify(site)})
	.then(function(response) {
		console.log('response.status = ' + response.status); // Will show you the status
		status = response.status;
		return response.json();
	})
	.then(data => {
	})
	.catch((error) => {
		console.error('Error:', error);
	});
		
}
